import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { createGlobalStyle, styled } from "styled-components";
import background from "./assets/background.jpg";
import { DetailPage } from "./components/DetailPage/DetailPage";
import Events from "./components/Events";
import Groovecast from "./components/Groovecast";
import LandingPage from "./components/LandingPage";
import Menu from "./components/Menu";
import Vermietung from "./components/Reeservation/Vermietung";
import Shop from "./components/Shop/Shop";

const GlobalStyle = createGlobalStyle`
  body {
  background-image: url(${background});
  }
`;
const App: React.FC = () => {
  return (
    <AppContainer>
      <GlobalStyle />
      <Router>
        <Menu />
        <Content>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/groovecast" element={<Groovecast />} />
            <Route path="/vermietung" element={<Vermietung />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/events" element={<Events />} />
            <Route path="/" element={<Events />} />
            <Route path="/details" element={<DetailPage />} />
          </Routes>
        </Content>
      </Router>
    </AppContainer>
  );
};

export default App;

const AppContainer = styled.div`
  /* background-image: url('/assets/background.jpg'); */
  background-color: #8c52ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
