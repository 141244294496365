import avantiLogo from "../../assets/avantiLogo.jpg";

export type RentalProduct = {
  id: number;
  name: string;
  image: string;
  description: string;
  pricePerDay: number;
};

export const products: RentalProduct[] = [
  {
    id: 1,
    name: "Party Light",
    image: avantiLogo,
    description:
      "Ein buntes Partylicht für stimmungsvolle Beleuchtung bei jeder Feier.",
    pricePerDay: 50,
  },
  {
    id: 2,
    name: "Flame Laser Light",
    image: avantiLogo,
    description:
      "Ein beeindruckendes Lasereffektlicht, das flammenähnliche Muster erzeugt.",
    pricePerDay: 50,
  },
  {
    id: 3,
    name: "Schwarzlicht LED",
    image: avantiLogo,
    description:
      "Perfekt für Neonpartys und beeindruckende Lichteffekte mit UV-Strahlen.",
    pricePerDay: 50,
  },
  {
    id: 4,
    name: "Lichteffekt Strahler",
    image: avantiLogo,
    description:
      "Ein vielseitiger Strahler, der verschiedene Lichteffekte erzeugt.",
    pricePerDay: 50,
  },
  {
    id: 5,
    name: "Schwarzlicht Leiste",
    image: avantiLogo,
    description:
      "Eine praktische UV-Leiste für großflächige Schwarzlichteffekte.",
    pricePerDay: 50,
  },
  {
    id: 6,
    name: "Nebelmaschine Klein",
    image: avantiLogo,
    description: "Kompakte Nebelmaschine für kleine Räume und intime Partys.",
    pricePerDay: 50,
  },
  {
    id: 7,
    name: "Nebelmaschine Groß",
    image: avantiLogo,
    description:
      "Eine leistungsstarke Nebelmaschine für größere Veranstaltungen.",
    pricePerDay: 50,
  },
  {
    id: 8,
    name: "Spotlights",
    image: avantiLogo,
    description: "Perfekt, um bestimmte Bereiche oder Objekte hervorzuheben.",
    pricePerDay: 50,
  },
  {
    id: 9,
    name: "Subwoofer Horn Klein",
    image: avantiLogo,
    description: "Kompakter Subwoofer mit kräftigem Bass für kleinere Events.",
    pricePerDay: 50,
  },
  {
    id: 10,
    name: "Subwoofer Horn Groß",
    image: avantiLogo,
    description: "Großer Subwoofer mit sattem Sound für große Veranstaltungen.",
    pricePerDay: 50,
  },
  {
    id: 11,
    name: "Sub mit Höhe",
    image: avantiLogo,
    description: "Ein leistungsstarker Subwoofer, ideal für Bühnen-Setups.",
    pricePerDay: 50,
  },
  {
    id: 12,
    name: "The Box Kicker",
    image: avantiLogo,
    description: "Ein tragbarer Lautsprecher mit kraftvollem Klang.",
    pricePerDay: 50,
  },
  {
    id: 13,
    name: "DDJ-800",
    image: avantiLogo,
    description:
      "Ein vielseitiger DJ-Controller für professionelle Performances.",
    pricePerDay: 50,
  },
  {
    id: 14,
    name: "XDJ-XZ",
    image: avantiLogo,
    description: "Ein hochwertiger DJ-Controller mit integriertem Mixer.",
    pricePerDay: 50,
  },
  {
    id: 15,
    name: "XDJ-RX3",
    image: avantiLogo,
    description: "Modernster All-in-One-DJ-Controller für kreative Sets.",
    pricePerDay: 50,
  },
];
