import axios from "axios";
import React, { useState } from "react";
import "./ProductGrid.css";
import { RentalProduct } from "./products";

type ProductGridProps = {
  products: RentalProduct[];
  onSelect: (product: RentalProduct) => void;
};

const ProductGrid: React.FC<ProductGridProps> = ({ products, onSelect }) => {
  const [reservation, setReservation] = useState<RentalProduct[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleProductClick = (product: RentalProduct) => {
    setReservation([...reservation, product]);
    onSelect(product);
  };

  const handleRemoveProduct = (index: number) => {
    const updatedReservation = [...reservation];
    updatedReservation.splice(index, 1);
    setReservation(updatedReservation);
  };

  const calculateDays = () => {
    if (!startDate || !endDate) return 1;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = Math.ceil(
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    );
    return Math.max(diff, 1); // Minimum 1 Tag
  };

  const totalPrice = reservation.reduce(
    (sum, product) => sum + product.pricePerDay * calculateDays(),
    0
  );

  const handleReservation = async () => {
    if (!reservation.length || !startDate || !endDate || !email || !phone) {
      alert("Bitte füllen Sie alle Felder aus!");
      return;
    }

    const reservationData = {
      products: [
        {
          id: 1,
          name: "Mietprodukt A",
          price: 100,
        },
        {
          id: 2,
          name: "Mietprodukt B",
          price: 50,
        },
      ],
      totalPrice: 150,
      startDate: "2024-12-01",
      endDate: "2024-12-07",
      email: "kunde@example.com",
      phone: "0123456789",
    };

    try {
      axios
        .post(
          "https://script.google.com/macros/s/AKfycby3O4RYXOkBWb_JFa_aAMaUPGKCLM6tHnIm03MwDl24u894C6l3QDdCzORGwwMrl-5n/exec",
          reservationData
        )
        .then((response) => {
          console.log("Reservierung erfolgreich:", response.data);
          alert("Reservierung erfolgreich!");
        })
        .catch((error) => {
          console.error("Fehler bei der Anfrage:", error);
          alert("Ein unbekannter Fehler ist aufgetreten.");
        });

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby3O4RYXOkBWb_JFa_aAMaUPGKCLM6tHnIm03MwDl24u894C6l3QDdCzORGwwMrl-5n/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reservationData),
        }
      );

      const result = await response.json();
      if (result.success) {
        alert("Reservierung erfolgreich eingetragen!");
      } else {
        alert("Fehler bei der Reservierung: " + result.message);
      }
    } catch (error) {
      console.error("Fehler bei der Reservierung:", error);
      alert("Ein Fehler ist aufgetreten. Bitte später erneut versuchen.");
    }
  };

  return (
    <div className="product-grid">
      <div className="selected-product">
        <h2>Reservierte Produkte</h2>
        <ul>
          {reservation.map((product, index) => (
            <li key={index}>
              {product.name} - {product.pricePerDay} €
              <button
                className="remove-product"
                onClick={() => handleRemoveProduct(index)}
              >
                -{" "}
              </button>
            </li>
          ))}
        </ul>
        <br />
        <label>
          Startdatum:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <br />
        <label>
          Enddatum:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <hr />
        <p style={{ marginTop: "1rem", fontSize: "1.2rem" }}>
          Gesamtbetrag: <strong>{totalPrice.toFixed(2)} €</strong>
        </p>
        <div className="reservation-form">
          <input
            type="email"
            placeholder="E-Mail-Adresse"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="tel"
            placeholder="Telefonnummer"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <button onClick={handleReservation}>Reservierung anfragen</button>
        </div>
      </div>
      {products.map((product, index) => (
        <div key={index} className="product-card">
          <img
            src={product.image}
            alt={product.name}
            className="product-image"
          />
          <div className="product-details">
            <h2 className="product-name">{product.name}</h2>
            <p className="product-description">{product.description}</p>
            <p className="product-price">{product.pricePerDay} €/Tag</p>
            <button
              onClick={() => handleProductClick(product)}
              className="add-product"
            >
              Produkt hinzufügen
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
