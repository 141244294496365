import React from "react";
import ProductGrid from "./ProductGrid";
import "./Vermietung.css"; // Optional: CSS für Styling importieren
import { products, RentalProduct } from "./products";

const Vermietung: React.FC = () => {
  const handleProductSelect = (product: RentalProduct) => {
    console.log("Ausgewähltes Produkt:", product);
  };
  return (
    <div className="vermietung-container">
      <header className="vermietung-header">
        <h1>Produkte zur Vermietung</h1>
      </header>
      <ProductGrid products={products} onSelect={handleProductSelect} />
    </div>
  );
};

export default Vermietung;
