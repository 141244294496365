import avantiLogo from "./assets/avantiLogo.jpg";
import avantiOneYear from "./assets/events/avantiOneYear.jpg";
import bahnwärter from "./assets/events/bahnwärter.jpeg";
import avantixart from "./assets/events/groovemusicartopener.jpg";
import hardGrooveMeetsHardTechno from "./assets/events/Hard_Groove_Meets_Hard_Techno.png";
import roarOfDamnation from "./assets/events/roar_of_damnation.jpg";
import roarOfDamnationII from "./assets/events/roar_of_damnation_II.png";
import synphonydernacht from "./assets/events/synphonydernacht.jpeg";

import { Event, Product } from "./types";

export const upcomingEvents: Event[] = [
  {
    id: "7",
    title: "Avanti 1 Year",
    date: "2024-12-14",
    location: "Schwarzer Adler",
    description: `🎉 1 Jahr AvantiKollektiv – das große Jubiläum im Adler! 🎉

Am 14.12. feiern wir unser 1-jähriges Bestehen und laden euch ein zu einer unvergesslichen Techno Nacht im Adler! Mit 13 DJs, die auf 3 Floors verteilt sind, bringen wir euch harten Techno, Hardgroove, Bounce und alles, was das Raver-Herz begehrt. 🦅🔥

Headliner ist niemand Geringerer als DJACHIMFEUERVOGEL – bekannt für seine explosiven Sets und mächtigen Beats. Neben ihm stehen 12 weitere Artists an den Decks, die euch mit bassgeladenen Sounds die Nacht durchpowern. 💣

Ein herzliches Dankeschön an jede*n, der uns in diesem ersten Jahr begleitet, unsere Partys gefeiert und unseren Podcast supportet hat! Ein besonderes Shoutout an alle DJs aus unserer Podcast-Reihe, die uns mit ihren Sets inspiriert und begeistert haben. Wir sind bereit für ein weiteres Jahr voller Techno, Raves und gemeinsamer Energie!

Seid dabei und lasst uns gemeinsam das feiern, was wir zusammen aufgebaut haben! 💜`,
    link: "https://www.instagram.com/p/DCMfVANo5am/?img_index=1",
    ticketLink: "https://de.ra.co/events/2022349", // Adjusted for this event
    image: avantiOneYear, // Assuming this is the name of the image variable
  },
];

export const pastEvents: Event[] = [
  {
    id: "655",
    title: "Avanti X Raversclaw",
    date: "2024-11-22",
    location: "Bahnwärter Thiel",
    description: `Seid bereit für eine unvergessliche Nacht voller pulsierender Beats! Am 22. November präsentieren das Avantikollektiv und Klangart eine geballte Ladung Hardtechno, die euch bis in die frühen Morgenstunden in Bewegung hält.

    Der Headliner sorgt mit seinem kompromisslosen Sound für ein schweißtreibendes Erlebnis! Doch das Line-up hat noch viel mehr zu bieten: Freut euch auf die energischen Sets von Jui Raven, 3 Days Awake, Deschloro, Platony, Govi und Pat.Wav. Von harten Kicks bis hin zu hypnotischen Melodien – hier ist für jeden etwas dabei!
    
    Sichert euch euren Platz auf dem Dancefloor und feiert mit uns bis zum Morgengrauen! Seid dabei und lasst euch von der Kraft der Musik mitreißen!`,
    link: "https://www.instagram.com/p/DAk52E-IBp3/",
    // ticketLink: "https://de.ra.co/events/2022348",
    image: bahnwärter,
  },
  {
    id: "1",
    title: "Simphony der Nacht",
    date: "2024-11-22",
    location: "Schwarzer Adler",
    description: `Seid bereit für eine unvergessliche Nacht voller pulsierender Beats! Am 22. November präsentieren das Avantikollektiv und Klangart eine geballte Ladung Hardtechno, die euch bis in die frühen Morgenstunden in Bewegung hält.

    Der Headliner sorgt mit seinem kompromisslosen Sound für ein schweißtreibendes Erlebnis! Doch das Line-up hat noch viel mehr zu bieten: Freut euch auf die energischen Sets von Jui Raven, 3 Days Awake, Deschloro, Platony, Govi und Pat.Wav. Von harten Kicks bis hin zu hypnotischen Melodien – hier ist für jeden etwas dabei!
    
    Sichert euch euren Platz auf dem Dancefloor und feiert mit uns bis zum Morgengrauen! Seid dabei und lasst euch von der Kraft der Musik mitreißen!`,
    link: "https://www.instagram.com/p/DAk52E-IBp3/",
    ticketLink: "https://de.ra.co/events/2022348",
    image: synphonydernacht,
  },
  {
    id: "2",
    title: "Groove Music &// Art Opener",
    date: "2024-10-25",
    location: "Drop In (Kempten)",
    description: `Welcome to Avanti – where the heart of the Allgäu meets a unique celebration of art, music, and good times!
    We’re thrilled to have found our place in Kempten and can’t wait to share this one-of-a-kind experience with you.
    
    What to Expect:
    
    Sound System:
    Get ready for an incredible auditory experience with our brand-new, self-made high-end sound system, along with a fresh DJ booth to keep the beats going all night.
    
    Indoor Room Opening:
    After 21:00, our indoor space opens, creating the perfect atmosphere for you to dance until the early hours.
    
    Age Limit:
    Entry is 18+ to ensure everyone has a great time.
    
    Payment Options:
    We accept CARD, CASH, and Bitcoin throughout the club.
    
    Tickets:
    You can grab your tickets on RA or at the door using CARD or Bitcoin. Please note, an RA ticket does not guarantee entry, but don’t worry – any purchased tickets will be refunded if you’re unable to get in.
    
    Special Features:
    We’re not just about the music – we’re about the full experience!
    
    Video mapping
    Light shows
    Art gallery
    Shader and digital art installations
    There’s something for every mood, with grooves to match your vibe. Enjoy amazing music, connect with fantastic people, and dive into an unforgettable night.
    
    We can’t wait to welcome you to Avanti and create memories together!`,
    link: "https://www.instagram.com/p/DA6Td4ooZes/?img_index=1",
    ticketLink: "https://de.ra.co/events/2005094",
    image: avantixart,
  },
  {
    id: "3",
    title: "Roar of Damnation II",
    date: "2021-01-03",
    location: "Schwarzer Adler",
    description: `Aber der wahre Höhepunkt? Das Finale war ein wahrhaft apokalyptischer Moment. Die Musik raste auf ein Crescendo zu, bei dem sich sogar die Luft förmlich verdichtete – und dann passierte es. Der Bass explodierte mit solcher Macht, dass er nicht nur den Boden unter unseren Füßen erbeben ließ, sondern sich wie eine Schockwelle durch das gesamte Allgäu zog. Es war, als würde die Erde selbst vibrieren, jeder Beat jagte wie ein Donnerschlag durch die Nacht und hinterließ Gänsehaut pur.

    Unser Equipment – speziell gebaut, um auch die härtesten Technobeats zu überstehen – wurde einfach ausgelöscht. Die Boxen? Zerfetzt. Die Verstärker? Am Limit. Alles, was wir erschaffen hatten, um dem Sound standzuhalten, brach in sich zusammen, als der Bass über das zulässige Maß hinauswuchs und die Grenzen des Vorstellbaren sprengte. Selbst die Zuschauer am Rande, die nur eine Ahnung von der bassgeladenen Kraft hatten, spürten die Vibrationen bis ins Mark. Es gab kein Entkommen; das war kein normales Rave, das war eine Soundapokalypse, die das Allgäu für eine Nacht in eine dystopische, technoid-pulsierende Traumwelt verwandelte.
    
    Die Energie war so stark, dass selbst die Nachbargemeinden von der Beben und dem unaufhaltsamen Dröhnen erzählten, und man schwört noch heute, dass die Erde an diesem Abend für einen Moment den Atem anhielt. "Roar of Damnation II" hat nicht nur die Nacht erobert – es hat eine Spur der Ekstase und Destruktion hinterlassen, wie es nur die wahre Macht des Techno kann.`,
    link: "https://www.instagram.com/p/C4-6Vu0od22/?img_index=1",
    image: roarOfDamnationII,
  },
  {
    id: "4",
    title: "Roar of Damnation",
    date: "2021-01-01",
    location: "Schwarzer Adler",
    description: `"Roar of Damnation" war mehr als nur ein Techno-Event – es war ein unaufhaltsamer Sturm aus Sound und Energie, der alle Grenzen sprengte. Die Nacht begann mit einem pulsierenden Intro, das sofort die Wände erzittern ließ und die Menge in ihren Bann zog. Mit jedem Track wurde die Musik härter, die Bässe tiefer und die Beats schneller, bis die Luft förmlich vibrierte. Es war, als ob die DJs eine unbändige Urkraft entfesselt hätten, die den Raum in eine rauschhafte Parallelwelt verwandelte. Das Lichtspiel flackerte im Takt, mal in grellen Neonfarben, mal im düsteren Stroboskop-Blitz, sodass man zwischen Momenten der Klarheit und völliger Dunkelheit hin- und hergerissen wurde. Die Masse verlor sich in dieser hypnotischen Dunkelheit, verbunden in einem gemeinsamen Rausch der Freiheit und Ekstase. Es gab keine Grenzen, keine Pausen – nur den alles verschlingenden Klang und das Gefühl, Teil eines unaufhaltsamen Beats zu sein.

    Doch die Nacht brachte mehr mit sich, als nur die Ekstase der Musik – sogar unsere selbstgebaute Anlage hielt der brutalen Kraft des Basses nicht stand. Mit voller Hingabe hatten wir jeden Lautsprecher und Verstärker optimiert, doch der ungezügelte Bass ließ sie letztendlich kapitulieren. Man konnte fast spüren, wie sich die Schallwellen verdichteten, bis schließlich die Boxen aufgaben und der Sound kurzzeitig in einer Art elektrischen Infernos erlosch. Für einen Moment herrschte Stille, ein Atemzug der Realität, bevor die Energie zurückkehrte und die Nacht unvermindert weiterging. Dieses Event hat nicht nur die Tanzfläche in eine tobende Welle aus Klang und Licht verwandelt, sondern auch unsere eigene Technik an ihre Grenzen gebracht – ein Beweis für die schiere, rohe Kraft von "Roar of Damnation".`,
    link: "https://www.instagram.com/p/C4-6Vu0od22/?img_index=1",
    image: roarOfDamnation,
  },
  {
    id: "5",
    title: "Hard Groove Meets Hard Techno",
    date: "2021-01-03",
    location: "Location 3",
    description: `Das erste Event von "Avanti" war nicht einfach nur ein Auftakt – es war ein Statement, das sich unauslöschlich in die Herzen und die Szene des Allgäus eingebrannt hat. Mit dieser Nacht haben wir eine völlig neue Ära eingeläutet, die das Potenzial dieser Region in den Mittelpunkt rückte und ihr eine Plattform gab, die bisher niemand für möglich gehalten hatte. Es war die Geburtsstunde einer Bewegung, ein mutiger Schritt, der das Allgäu in neuem Licht erscheinen ließ. Die Vision von "Avanti" wurde Realität: Wir wollten einen Raum schaffen, in dem Musik und Menschen im Einklang stehen und die Grenzen von Konventionen und Erwartungen gesprengt werden.

    Die Atmosphäre war elektrisierend – als sich die Türen öffneten, strömten die Leute voller Neugier und Vorfreude herein. Jeder Beat, jeder Lichtstrahl, jeder Moment war darauf ausgelegt, nicht nur ein Event, sondern eine ganze Erfahrung zu schaffen. Die Musik vibrierte, durchzog die Menge und verwandelte die Tanzfläche in einen lebendigen Organismus, der im Takt pulsierte. Es war, als ob das Allgäu selbst zum Leben erwachte, entfesselt und frei.
    
    Und es war mehr als nur eine Party: Der "Avanti"-Stempel brannte sich an diesem Abend in die Region ein. Dieser erste Schlag, diese erste Feier war ein Versprechen – ein Versprechen auf weitere Nächte, auf ein kulturelles Erwachen, das alles Bisherige übersteigen würde. "Avanti" war nicht nur eine Nacht voller Musik und Euphorie; es war ein Aufbruch, der die Grenzen sprengte und für das Allgäu eine neue Zukunft eröffnete. Wir haben etwas hinterlassen, das weiterwachsen und sich in die Herzen der Menschen einprägen würde – die Allgäuer Szene würde nie wieder dieselbe sein.`,
    link: "https://www.instagram.com/p/C3VmbU4N5AK/?img_index=1",
    image: hardGrooveMeetsHardTechno,
  },
];

export const products: Product[] = [
  {
    id: "1",
    name: "T-Shirt",
    description: "Cooles T-Shirt mit Avanti-Logo",
    price: 19.99,
    imageUrl: avantiLogo,
    sizes: ["XS", "S", "M", "L"],
  },
  {
    id: "2",
    name: "Pullover",
    description: "Warmer Pullover mit Avanti-Logo",
    price: 29.99,
    imageUrl: avantiLogo,
    sizes: ["XS", "S", "M", "L"],
  },
];
