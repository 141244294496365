import React from "react";
import styled from "styled-components";
import Card from "./Card";

type GrooveCastItem = {
  id: number;
  name: string;
  imageurl: string;
  link: string;
};

const grooveCastItems: GrooveCastItem[] = [
  {
    id: 1,
    name: "Groove Cast #1 - Nicola Mingrone | Hardgroove / 145 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-7hx6FXNnUfArjZmv-oqaRWw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-1-nicola-mingrone?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 2,
    name: "Groove Cast #2 - Explicit | Hard Groove / 150 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-i3zQOHq5zoewf1TY-OX6jLw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-2-explicit?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 3,
    name: "Groove Cast #3 - PHEEL (DE) | Garde Groove / 145 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-9mbVVgaYG7Nvvppa-F2Lxhw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-3-pheel-de?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 4,
    name: "Groove Cast #4 - Jui | Groove, Hardgroove / BPM 144-147",
    imageurl:
      "https://i1.sndcdn.com/artworks-2rS5YCOVAB6qfz1b-vYWyzw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-4-jui?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 5,
    name: "Groove Cast #5 - Sloli | Hard Groove, Trance / 142 - 144 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-U74Ky3qXYv2oRS6p-nrBQuQ-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-5-sloli?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 6,
    name: "Groove Cast #6 - R.D.J | Peak Time Groove / 137-149 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-jLF2bHlD6TICCaQT-jwr74Q-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-6-rdj?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 7,
    name: "Groove Cast #7 - Morpheus | Hardgroove, Groove / BPM 140-145",
    imageurl:
      "https://i1.sndcdn.com/artworks-IB2NuVw8gFFpPGZc-0gZCqQ-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-morpheus?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 8,
    name: "Groove Cast #8 - Esch | Bouncy, Groove, Hard Kicks / 150-165 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-xG0zazsvz3IKCpb4-Q9QE9w-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-7-esch?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 9,
    name: "Groove Cast #9 - D.B.R | Hard Groove / 148 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-FGXM4meFvSFNfvUW-yuZqlw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-9-dbr-hard-groove-148-bpm?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 10,
    name: "Groove Cast #10 - Steek | Hardgroove / 144-147 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-A1dxxgreKzIH23qi-41lFHg-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-10-steek?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 11,
    name: "Groove Cast #11 - LEVI AMBS | Oldschool Techno , 2000s Hardgroove / 144 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-MagvbBUzv7NhT43J-CDLIyA-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-10-levi-ambs?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 12,
    name: "Groove Cast #12 LIVE - Zona Interdunar | Industrial Groove / 150 BPM",
    imageurl:
      "https://i1.sndcdn.com/artworks-gnUziTlfi3SJgKEw-seUcvw-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-11-zona-interdunar?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 13,
    name: "Groove Cast #15 - Deschlorø | 150-160 bpm /",
    imageurl:
      "https://i1.sndcdn.com/artworks-9yCdlmyFYpyQG3xr-yDUTiA-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-11-deschloro-140-155-bpm?in=avantilabelgroup/sets/groovecast",
  },
  {
    id: 14,
    name: "Groove Cast #16 - Dyshell Comlexierence | Raw Deep Hypnotic / 134-139.99 bpm",
    imageurl:
      "https://i1.sndcdn.com/artworks-yLu0KCUV0mBFEC10-6mBEmQ-t250x250.jpg",
    link: "https://soundcloud.com/avantilabelgroup/groove-cast-dyshell?in=avantilabelgroup/sets/groovecast",
  },
];

const Groovecast: React.FC = () => {
  const handleSubmit = () => {
    const subject = encodeURIComponent("Mein Groove Cast");
    const body = encodeURIComponent(
      `Hallo Avanti,

mein Groove Cast ist da. Hier sind die Details:

Artist:
BPM:
Genre:
Tracklist (Link oder Text):
Social Media Links:

Vielen Dank!
`
    );
    window.location.href = `mailto:avantilabelgroup@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <GrooveCastContainer>
      <HeadLine>Groove Cast Season I</HeadLine>
      <hr />
      <GrooveCastText>
        Der Groove Cast ist ein spannender Podcast, der jede Woche donnerstags
        um 17 Uhr auf SoundCloud veröffentlicht wird. In jeder Episode
        präsentieren verschiedene Artists ihre einzigartigen und vielfältigen
        Sets. Von groovigen Beats bis hin zu mitreißenden Rhythmen bietet der
        Groove Cast eine abwechslungsreiche Musikauswahl, die jeden
        Musikliebhaber begeistert.
      </GrooveCastText>
      <CastContainer>
        {grooveCastItems.map((grooveCastItem) => (
          <Card
            key={grooveCastItem.id}
            title={grooveCastItem.name}
            link={grooveCastItem.link}
            image={grooveCastItem.imageurl}
            id={`groovecast-card-${grooveCastItem.id}`}
          />
        ))}
      </CastContainer>

      <GrooveCastText>
        Du bist ein Künstler und möchtest deinen eigenen Groove Cast einreichen?
        Wir freuen uns auf deine Einsendungen! Klicke auf den Button unten, um
        uns deinen Groove Cast per E-Mail zu schicken.
      </GrooveCastText>
      <StyledButton onClick={handleSubmit}>
        Groove Cast einschicken
      </StyledButton>
    </GrooveCastContainer>
  );
};

export default Groovecast;

const GrooveCastContainer = styled.div`
  margin-top: 4.5rem;
`;

const CastContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const HeadLine = styled.h1`
  text-align: center;
`;

const GrooveCastText = styled.p`
  text-align: center;
  margin: 2rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: block;
  margin: 20px auto;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0px 6px 20px rgba(0, 123, 255, 0.7);
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
  }
`;
